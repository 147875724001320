.Section {
  max-width: 100vw;

  padding: 12px;
}

.Section--heading,
.Section--left,
.Section--right {
  box-sizing: border-box;
  margin: 12px;
}

.Section--heading {
  margin: -12px -12px 12px;
  padding: 24px;
  background-color: #DCEDC8;
  font-weight: 500;
}

.Section--title,
.Section--buttons {
  display: inline-block;
}
.Section--buttons {
  margin-left: auto;
}

.Section--left {
  margin: 0; /* moved into children, below */

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.Section--left > * {
  margin: 12px;
  flex: 1;
  width: 0; /* even columns */
  min-width: 140px;
}

.Section--right {
  height: calc(45vw - 24px);
  min-height: 150px;
}


@media screen and (min-width: 769px) {
  /* narrow left column with scorecards, wider right column with charts */

  .Section {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }

  .Section--heading {
    flex: 1 0 100%;
  }

  .Section_withChart .Section--left {
    flex: 0 0 240px;
    flex-flow: column nowrap;
  }
  .Section_withChart .Section--left > * {
    width: auto;
  }

  .Section--right {
    flex: 1 1 461px;
    min-width: 100px;
    height: 280px;
  }
}

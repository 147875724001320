.Scorecard--value {
  font-size: 24px;
  line-height: 32px;
}

.Scorecard--prefix,
.Scorecard--suffix {
  color: rgba(0, 0, 0, 0.54);
}
.Scorecard--prefix {
  vertical-align: 0.4em; /* super */
}


.Comparison-type-positive .Comparison--value {
  color: #01579B;
}

.Comparison-type-negative .Comparison--value {
  color: #B71C1C;
}

.Comparison-type-neutral .Comparison--value {
  color: rgba(0, 0, 0, 0.54);
}

.Comparison--label {
  padding-left: 0.4em;
  color: rgba(0, 0, 0, 0.54);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Noto', sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  font-weight: 400; /* regular */
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}

h1, h2 {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

h1 {
  font-size: 20px;
  font-weight: 500; /* medium */
}

h2 {
  font-size: 16px;
  font-weight: 400; /* regular */
}

b, strong, th {
  font-weight: 500;
  /*line-height: 24px;*/
}

a, a:visited {
  color: #03A9F4;
  text-decoration: none;
}
a:hover, a:active {
  text-decoration: underline;
}

/* seems like there might be a better place for these... */
.NetworkIndicator.Progress {
  position: fixed;
  top: 0;
  left: 0;
  user-select: none;
}

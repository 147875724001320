/* adapted from default react-vis/dist/style.css; updated to Material-ish */

.Chart.rv-xy-plot { /* container for svg and regular DOM */
  position: relative;
}

.Chart .rv-xy-plot__inner { /* the svg */
  display: block;
  shape-rendering: crispedges;
}

.Chart .rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 1;
  stroke: rgba(0, 0, 0, 0.12);
  /*stroke: rgba(0, 0, 0, 0.54);*/
}

.Chart .rv-xy-plot__axis__tick__line {
  stroke: rgba(0, 0, 0, 0.12);
}

.Chart .rv-xy-plot__axis__tick__text {
  fill: rgba(0, 0, 0, 0.54);
}

.Chart .rv-xy-plot__axis__title text {
  fill: rgba(0, 0, 0, 0.87);
}

.Chart .rv-xy-plot__grid-lines__line {
  stroke: rgba(0, 0, 0, 0.12);
}

.Chart .rv-xy-plot__series,
.Chart .rv-xy-plot__series path {
  pointer-events: all;
}

.Chart .rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  shape-rendering: auto;
}

.Chart .rv-crosshair {
  position: absolute;
  pointer-events: none;

  transition:
    left 0.1s ease-in-out,
    right 0.1s ease-in-out;
}

.Chart .rv-crosshair__line {
  background: #689F38;
  width: 1px;
}

.Chart .rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0;
}

.Chart .rv-crosshair__inner--left {
  right: 1px;
}

.Chart .rv-crosshair__inner--right {
  left: 1px;
}

.Chart .rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;

  padding: 4px 0;
  color: rgba(0, 0, 0, 0.54);

  /* Gahh - legends won't position themselves in the flow with FlexibleXYPlot; see margin workaround in js */
  position: absolute;
  right: 0;
  bottom: 0;
}

.Chart .rv-discrete-color-legend.horizontal {
  white-space: nowrap;
}

.Chart .rv-discrete-color-legend-item {
  /*color: rgba(0, 0, 0, 0.54);*/
  border-radius: 1px;
  padding: 2px 12px;
}

.Chart .rv-discrete-color-legend-item.horizontal {
  display: inline-block
}

.Chart .rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  display: inline-block;
}

.Chart .rv-discrete-color-legend-item__color {
  display: inline-block;
  height: 2px;
  vertical-align: middle;
  width: 14px;
}

/* hack -- need to determine bar vs line series types */
.Chart .rv-discrete-color-legend-item:last-child .rv-discrete-color-legend-item__color {
  transform: scaleY(2);
}
.Chart .rv-discrete-color-legend-item:not(:last-child) .rv-discrete-color-legend-item__color {
  transform: scaleY(14);
}

.Chart .rv-discrete-color-legend-item__title {
  margin-left: 8px;
}

.Chart .rv-discrete-color-legend-item.disabled {
  color: rgba(0, 0, 0, 0.12);
}

.Chart .rv-discrete-color-legend-item.clickable {
  cursor: pointer;
}

.Chart .rv-discrete-color-legend-item.clickable:hover {
  background: #E1F5FE;
}

.Chart .rv-legend-titles {
  height: 16px;
  position: relative;
}

.Chart .rv-legend-titles__left,
.Chart .rv-legend-titles__right,
.Chart .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
}

.Chart .rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%;
}

.Chart .rv-legend-titles__right {
  right: 0;
}


/*
 * Tooltip (crosshair custom content)
 */

.Chart--tooltip {
  padding: 4px 12px;
  border-spacing: 0;

  background: #fff;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.Chart--tooltip th,
.Chart--tooltip td {
  white-space: nowrap;
  padding: 4px;
}

.Chart--tooltip th {
  text-align: center;
}

.Chart--tooltip td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  text-align: right;
}
